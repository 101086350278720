import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import { withCalendly } from "../types/calendly"

import * as DK from "design-kit"

import { WrappedBase } from "../shared-components/Shared/WrappedBase"
import Footer from "../shared-components/Footer"
import Navbar from "../shared-components/Navbar"

import { Hero, Intro } from "../views/ProtectionImportance/Hero"
import { BenefitList } from "../views/ProtectionImportance/BenefitList"

import cloud1 from "../assets/images/tinted-clouds/cloud1.png"
import cloud2 from "../assets/images/tinted-clouds/cloud2.png"
import cloud3 from "../assets/images/tinted-clouds/cloud3.png"

import config from "../../config.json"

const GetStarted: React.FC = () => (
  <DK.GridRow>
    <section
      css={css`
        ${DK.column({
          widthMobile: 2,
          offsetMobile: 1,
          widthTablet: 2,
          offsetTablet: 2,
          widthDesktop: 4,
          offsetDesktop: 4,
        })}
      `}
    >
      <DK.PrimaryCTA
        text="Get started"
        onClick={() => {
          withCalendly(Calendly => {
            Calendly.initPopupWidget({
              url: "https://calendly.com/d/cmbn-dzn-vq4/chat-with-a-protection-expert?primary_color=006be6?hide_landing_page_details=1",
            })
          })
        }}
      />
    </section>
  </DK.GridRow>
)

const LearnAboutTypes: React.FC = () => (
  <div
    css={css`
      text-align: center;
    `}
  >
    <DK.BodyBold>
      Find out more about the different{" "}
      <a href="/protection-types">types of protection</a>.
    </DK.BodyBold>
  </div>
)

const Layout = styled.div`
  margin: 0 auto;
  max-width: ${DK.maxWidth}px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  gap: ${DK.vertical.l};
`

const FALLBACK_GRADIENT = `linear-gradient(
  190deg,
  ${DK.colours.gradientBase.seafoam} 35%,
  ${DK.colours.gradientBase.mistyViolet}
)`

const Main = styled.main`
  position: relative;
  color: ${DK.colours.offBlack};
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${FALLBACK_GRADIENT};
  padding-bottom: ${DK.vertical.l};
  overflow: hidden;
`

const Clouds: React.FC = () => (
  <React.Fragment>
    <div
      css={css`
        position: relative;
        z-index: 0;
        max-width: ${DK.maxWidth}px;
        margin: 0 auto;
      `}
    >
      <img
        src={cloud1}
        alt=""
        css={css`
          width: 930px;
          position: absolute;
          z-index: -1;
          display: none;
          top: 100px;
          margin: auto;
          left: 0px;
          right: 0px;
          ${DK.breakpoints.desktop`
            display: block;
          `}
        `}
      />

      <img
        src={cloud2}
        alt=""
        css={css`
          opacity: 0.8;
          position: absolute;
          z-index: -1;
          display: none;
          top: 500px;
          left: 50px;
          ${DK.breakpoints.desktop`
            display: block;
          `}
        `}
      />

      <img
        src={cloud3}
        alt=""
        css={css`
          opacity: 0.8;
          position: absolute;
          z-index: -1;
          display: none;
          top: 500px;
          right: 50px;
          ${DK.breakpoints.desktop`
            display: block;
          `}
        `}
      />
    </div>
  </React.Fragment>
)

const PageContent: React.FC = () => (
  <React.Fragment>
    <Navbar theme="transparent-dark" />

    <Main id="main-content">
      <Clouds />
      <Layout>
        <div>
          <Hero />
          <Intro />
        </div>

        <BenefitList />
        <GetStarted />
        <LearnAboutTypes />
      </Layout>
    </Main>

    <Footer />
  </React.Fragment>
)

const ProtectionImportance: React.FC = () => (
  <WrappedBase
    metaTitle="Why protection is so important | Habito"
    metaDescription="Learn why protection is so important and how to get advice. Know the risks, and your options."
    canonicalUrl="https://www.habito.com/protection-importance"
    noIndex={false}
    pageName="protection-importance"
    intercom={true}
    config={config}
    head={[
      <link
        rel="preload"
        as="style"
        // TS thinks `onload` isn't a valid prop since it doesn't realise this
        // is going to be stringified into HTML
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onload="this.onload=null;this.rel='stylesheet';"
        href="//assets.calendly.com/assets/external/widget.css"
      />,
      <script async src="//assets.calendly.com/assets/external/widget.js" />,
    ]}
  >
    <PageContent />
  </WrappedBase>
)

export default ProtectionImportance
