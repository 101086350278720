export type Calendly = {
  initPopupWidget: (options: { url: string }) => void
}

declare global {
  interface Window {
    Calendly?: Calendly
  }
}

export const withCalendly = (f: (calendly: Calendly) => void): void => {
  if (typeof window === "undefined") return
  if (typeof window.Calendly === "undefined") return
  return f(window.Calendly)
}
