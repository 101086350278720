import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import * as DK from "design-kit"

import bicep from "../../assets/images/misc/tablet-bicep.png"
import bicepWebp from "../../assets/images/misc/tablet-bicep.webp"
import houseEyes from "../../assets/images/misc/tablet-house-eyes.png"
import houseEyesWebp from "../../assets/images/misc/tablet-house-eyes.webp"
import thumbsUp from "../../assets/images/misc/tablet-thumbs-up.png"
import thumbsUpWebp from "../../assets/images/misc/tablet-thumbs-up.webp"
import trainers from "../../assets/images/misc/tablet-trainers.png"
import trainersWebp from "../../assets/images/misc/tablet-trainers.webp"

const BenefitStack = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${DK.vertical.xs};
  ${DK.breakpoints.tablet`
    gap: ${DK.vertical.l};
  `}
  ${DK.column({
    widthMobile: 4,
    offsetMobile: 0,
    widthTablet: 6,
    offsetTablet: 0,
    widthDesktop: 8,
    offsetDesktop: 2,
  })}
`

const BenefitRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${DK.vertical.s};
`

const BenefitCard: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <DK.Card
    element="div"
    borderRadius="m"
    kind="semi-transparent"
    css={css`
      ${DK.breakpoints.tablet`
        display: flex;
        gap: ${DK.vertical.s};
      `}
    `}
  >
    {children}
  </DK.Card>
)

const BenefitTitle = styled.h3`
  ${DK.typographyStyles.headline1}
  flex: 3;
`

const BenefitBody = styled(DK.Body)`
  margin-top: ${DK.vertical.s};
  ${DK.breakpoints.tablet`
    margin-top: 0;
    flex: 3;
  `}
  ${DK.breakpoints.desktop`
    flex: 2;
  `}
`

const BenefitImage: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const isTablet = DK.useMediaQuery(DK.breakpoints.tablet.query)
  const hasMounted = DK.useHasMounted()
  return isTablet ? (
    <picture
      css={css`
        &,
        & * {
          width: 150px;
        }
        ${DK.breakpoints.desktop`
          &,
          & * {
            width: 200px;
          }
        `}
      `}
      key={`${hasMounted}`}
    >
      {children}
    </picture>
  ) : null
}

export const BenefitList: React.FC = () => (
  <DK.GridRow>
    <BenefitStack>
      <BenefitRow>
        <BenefitCard>
          <BenefitTitle>Peace of mind</BenefitTitle>
          <BenefitBody>
            Knowing that you and your loved ones are financially protected can
            give you peace of mind, especially during difficult times.
          </BenefitBody>
        </BenefitCard>

        <BenefitImage>
          <source srcSet={thumbsUpWebp} type="image/webp" />
          <img src={thumbsUp} alt="" />
        </BenefitImage>
      </BenefitRow>

      <BenefitRow>
        <BenefitImage>
          <source srcSet={bicepWebp} type="image/webp" />
          <img src={bicep} alt="" />
        </BenefitImage>

        <BenefitCard>
          <BenefitTitle>Financial security</BenefitTitle>
          <BenefitBody>
            <DK.TextLink
              text="Life"
              aria-label="Learn about life cover"
              href="https://help.habito.com/en/articles/9470527-life-cover"
              target="_blank"
              rel="noopener noreferrer"
            />
            ,{" "}
            <DK.TextLink
              text="critical illness"
              aria-label="Learn about critical illness cover"
              href="https://help.habito.com/en/articles/9543871-critical-illness-cover-cic"
              target="_blank"
              rel="noopener noreferrer"
            />
            , and{" "}
            <DK.TextLink
              text="income protection"
              aria-label="Learn about income protection"
              href="https://help.habito.com/en/articles/9543890-income-protection-ip"
              target="_blank"
              rel="noopener noreferrer"
            />{" "}
            can provide financial security for you and your loved ones if
            something unexpected happens.
          </BenefitBody>
        </BenefitCard>
      </BenefitRow>

      <BenefitRow>
        <BenefitCard>
          <BenefitTitle>Focus on what’s important</BenefitTitle>
          <BenefitBody>
            Having the right protection in place allows you the time to focus on
            you; recovering and getting well again.
          </BenefitBody>
        </BenefitCard>

        <BenefitImage>
          <source srcSet={trainersWebp} type="image/webp" />
          <img src={trainers} alt="" />
        </BenefitImage>
      </BenefitRow>

      <BenefitRow>
        <BenefitImage>
          <source srcSet={houseEyesWebp} type="image/webp" />
          <img src={houseEyes} alt="" />
        </BenefitImage>

        <BenefitCard>
          <BenefitTitle>Keep your home</BenefitTitle>
          <BenefitBody>
            Protection can help you continue paying, or pay off, your mortgage.
          </BenefitBody>
        </BenefitCard>
      </BenefitRow>
    </BenefitStack>
  </DK.GridRow>
)
