import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/react"

import * as DK from "design-kit"

import upload from "../../views/ProtectionImportance/assets/hero/upload/tablet-upload.png"
import uploadWebp from "../../views/ProtectionImportance/assets/hero/upload/tablet-upload.webp"
import uploadDesktop from "../../views/ProtectionImportance/assets/hero/upload/desktop-upload.png"
import uploadDesktopWebp from "../../views/ProtectionImportance/assets/hero/upload/desktop-upload.webp"

const HeroImage: React.FC = () => {
  const isDesktop = DK.useMediaQuery(DK.breakpoints.desktop.query)
  const hasMounted = DK.useHasMounted()
  return isDesktop ? (
    <picture
      key={`${hasMounted}`}
      css={css`
        position: absolute;
        z-index: 0;
        top: -120px;
        right: -160px;
      `}
    >
      <source srcSet={uploadDesktopWebp} type="image/webp" />
      <img src={uploadDesktop} alt="" />
    </picture>
  ) : (
    <picture
      css={css`
        position: absolute;
        z-index: 0;
        transform: scale(0.5);
        top: -190px;
        left: 0px;
        right: 0px;
        margin: auto;
        text-align: center;
        ${DK.breakpoints.tablet`
          transform: revert;
          left: revert;
          top: -25px;
          right: -30px;
        `}
      `}
    >
      <source srcSet={uploadWebp} type="image/webp" />
      <img src={upload} alt="" />
    </picture>
  )
}

const HeroText = styled(DK.Statement1)`
  position: relative;
  z-index: 1;
  margin-right: 0px;
  ${DK.breakpoints.tablet`
    margin-right: 170px;
  `}
  ${DK.breakpoints.desktop`
    margin-right: 205px;
  `}
`

export const Hero: React.FC = () => (
  <DK.GridRow>
    <div
      css={css`
        position: relative;
        margin-top: calc(${DK.vertical.xl} + ${DK.vertical.xl});
        margin-bottom: ${DK.vertical.m};

        ${DK.breakpoints.desktop`
          margin-top: calc(${DK.vertical.xxl} + ${DK.vertical.xxl});
          margin-bottom: ${DK.vertical.l};
        `}

        ${DK.column({
          widthMobile: 4,
          offsetMobile: 0,
          widthTablet: 6,
          offsetTablet: 0,
          widthDesktop: 10,
          offsetDesktop: 1,
        })}
      `}
    >
      <HeroText>Why is protection so important?</HeroText>
      <HeroImage />
    </div>
  </DK.GridRow>
)

export const Intro: React.FC = () => (
  <DK.GridRow>
    <section
      css={css`
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        gap: ${DK.vertical.s};

        ${DK.column({
          widthMobile: 4,
          offsetMobile: 0,
          widthTablet: 4,
          offsetTablet: 0,
          widthDesktop: 6,
          offsetDesktop: 2,
        })}
      `}
    >
      <DK.Headline>
        Life is full of uncertainties. Whilst you can’t predict the future, you
        can prepare for it.
      </DK.Headline>

      <DK.Headline>
        If you die, or become too ill to work, there are protection products
        that can help to protect you and your loved ones.
      </DK.Headline>

      <DK.Headline>
        Even if you don’t think you need protection, it’s worth getting advice.
        Know the risks, and your options.
      </DK.Headline>
    </section>
  </DK.GridRow>
)
